
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Header from "components/Header/Header";
import Layout from "components/Layout";
import { GetStaticProps } from "next";
import Head from "next/head";
import Image from "next/image";

export default function Custom404() {
  return (
    <div>
      <Head>
        <title>{process.env.NEXT_PUBLIC_PRODUCTNAME}</title>
        <meta name="description" content="Error" />
      </Head>
      <Header />

      <main className="relative h-screen">
        <div className="flex justify-center text-center">
          <h1 className="relative z-10 bg-gradient-to-r from-indigo-600 to-fuchsia-600 bg-clip-text px-1 py-1 pt-10  text-4xl font-black text-slate-400 text-transparent md:px-2 md:text-8xl ">
            <span className="relative z-10 mb-8 inline-block pt-5 text-3xl font-black md:text-8xl">
              404
            </span>
          </h1>
        </div>
        <Image src="/img/404.jpg" fill alt="foggy forest" />
      </main>
      <footer></footer>
    </div>
  );
}

 const _getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      test: 1,
    },
    revalidate: 1,
  };
};


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  